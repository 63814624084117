.rconfigs {
  margin: 20px auto;
  margin-top: 30px;
  width: calc(100% - 40px);
}

.rconfigs *,
.rconfigs *::before,
.rconfigs *::after {
  box-sizing: border-box;
}

.rconfigs table {
  border-collapse: collapse;
  width: 100%;
}

.rconfigs table tr {
  border-bottom: 1px solid #ddd;
}

.rconfigs table tr td {
  padding: 3px 5px;
}

.rconfigs table tr th {
  border-bottom: 2px solid #ddd;
}

.rconfigs table tr:hover td {
  background-color: #eee;
}

.rconfigs table tr th:hover {
  background-color: transparent;
}

.rconfigs .h1,
.rconfigs .h2,
.rconfigs .h3,
.rconfigs .h4,
.rconfigs .h5,
.rconfigs .h6,
.rconfigs h1,
.rconfigs h2,
.rconfigs h3,
.rconfigs h4,
.rconfigs h5,
.rconfigs h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.rconfigs h1,
.rconfigs h2,
.rconfigs h3,
.rconfigs h4,
.rconfigs h5,
.rconfigs h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.rconfigs h5,
.rconfigs .h5 {
  font-size: 1.25rem;
}

.rconfigs .mainNav {
  border-bottom: 1px solid #ddd;
  background: #fafafa;
}

.rconfigs .mainNav h1 {
  font-size: 16pt;
}

.rconfigs .json_to_html_table {
  border: 1px solid #ddd;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 3px;
  word-break: break-all;
}

.rconfigs .method-box {
  margin-bottom: 10px;
  border-radius: 0 !important;
  color: #406090;
  background-color: #faffff !important;
  border-color: #ccddff !important;
}

.rconfigs .method-box .card-header {
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  border-color: #ccddff !important;
}

.rconfigs .method-box .card-body {
  padding: 0.75rem;
}

.rconfigs .method-box .error {
  background-color: #ffeeee;
  color: #bb1111;
}

/* CONFIG */
.rconfigs .remote-config-card {
  margin-bottom: 5px;
  font-size: 12px;
}
.rconfigs .remote-config-card .card-header {
  padding: 0 10px;
  font-weight: bold;
  background-color: #e3eaf1;
}
.rconfigs .remote-config-card .card-body {
  padding: 0 10px;
}
.rconfigs .remote-config-card .form-group {
  margin: 0 !important;
}
.rconfigs .remote-config-card hr {
  margin: 5px -10px;
  border-color: #e3eaf1;
}

.rconfigs .remote-config-card.active {
  border-color: #b4daff;
}

.rconfigs .remote-config-card.active .card-header {
  background-color: #b4daff;
}
.rconfigs .remote-config-card.active hr {
  border-color: #b4daff;
}

.rconfigs .checkbox-config input[type="checkbox"] {
  margin: 0 !important;
  margin-top: 5px !important;
}

.rconfigs .checkbox-config label {
  margin-left: 15px;
  line-height: 12px;
  margin-top: 5px;
}

.rconfigs .app-icon span {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10pt;
  color: #444;
}

.rconfigs .load-status {
  text-align: center;
  font-size: 8pt;
}

.rconfigs .load-status .alert {
  padding: 6.9px 20px;
  margin-bottom: 0;
}

.rconfigs .config-input span.key {
  font-size: 10pt;
  font-weight: bold;
  color: #444;
}

.rconfigs .nav-tabs .nav-link {
  cursor: pointer;
}

.rconfigs .config-input-boolean input[type="checkbox"] {
  margin-left: 0;
}

.rconfigs .card-json .card-header {
  padding: 0.5rem 0.75rem;
}

.rconfigs .card-json .card-footer {
  background: #eee;
  padding: 0.75rem;
}

.rconfigs .card-json.bg-primary .card-header {
  color: white;
}

.rconfigs .card-json .card-footer .json_to_html_table {
  border: none;
}

.rconfigs .i18nBlock {
  padding-left: 10px;
}

.rconfigs .i18nBlock .title {
  margin-left: -10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.rconfigs .i18nBlock .collapseBtn {
  cursor: pointer;
  display: inline-block;
  border: 1px solid;
  line-height: 11px;
  height: 16px;
  width: 16px;
  text-align: center;
  border-radius: 4px;
  margin-right: 10px;
}

.rconfigs .app-info-update {
  width: 300px;
  display: flex;
}

.rconfigs .app-info-update .logo {
  display: inline-block;
  height: 80px;
  width: 80px;
  margin-right: 10px;
  border: 1px solid #eaeaea;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #f0f0f0;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: #fff;
}

.rconfigs .app-info-update .details {
  display: inline-block;
}

.rconfigs .app-info-update .details .name {
  display: block;
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
}

.rconfigs .app-info-update .details .version {
  display: block;
  font-weight: bold;
  line-height: 40px;
}

.rconfigs .note {
  font-size: 9pt;
  margin-top: 0px;
  display: block;
}

/* MVP */
.rconfigs .mvp-sidebar {
  display: inline;
}
.rconfigs .mvp-map {
  position: fixed;
  right: 0;
  height: 100%;
  width: 100%;
  bottom: 0;
  top: 50px;
  left: 300px;
}

.rconfigs .mvp-map #example-map {
  height: 100%;
}
